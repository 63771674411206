
import { defineComponent, onMounted, ref } from 'vue';

import { ElLoading } from 'element-plus';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

import useFavorite from '@/uses/useFavorite';

export default defineComponent({
  name: 'Favorites',

  components: {
    DashboardLayout,
  },

  setup() {
    const { favoritesResponse, fetchFavorites } = useFavorite();

    const columns = ref([{
      prop: 'favourite_name',
      label: 'Name',
    }, {
      prop: 'view_name',
      label: 'Type Of View',
    }, {
      prop: 'owner',
      label: 'Owner',
    }, {
      prop: 'summary',
      label: 'Summary of Changes',
    }]);

    onMounted(async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        await fetchFavorites();
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      favoritesResponse,
      columns,
    };
  },
});

